import React, {useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import SupplierService from "../../services/SupplierService";

import {Alert, Container} from "react-bootstrap";
//import SupplierEditModal from "./SupplierEditModal";

import 'bootstrap/dist/css/bootstrap.min.css';

import BTable from 'react-bootstrap/Table';

import { useTable } from 'react-table'

//TODO make this a general component so that it may be reused (styling etc) between all the tables in the application
function Table({ columns, data }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data,
    })

    // Render the UI for your table
    return (
        <BTable striped bordered hover size="sm" {...getTableProps()}>
            <thead>
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps()}>
                            {column.render('Header')}
                        </th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody>
            {rows.map((row, i) => {
                prepareRow(row)
                return (
                    <tr {...row.getRowProps()}>
                        {row.cells.map(cell => {
                            return (
                                <td {...cell.getCellProps()}>
                                    {cell.render('Cell')}
                                </td>
                            )
                        })}
                    </tr>
                )
            })}
            </tbody>
        </BTable>
    )
}

function SupplierTable() {
    //TODO convert alert to useAlert hook that can be used anywhere in application (maybe?)
    const [alert, setAlert] = useState({visible: true, alertMsg: "Test", alertVariant: 'danger'});
    //TODO set the initial value of suppliers to passed in props value, once that functionality is moved to the supplier view container
    const [suppliers, setSuppliers] = useState([]);
    const columns = React.useMemo(
        () => [
            {
                Header: 'Supplier Code',
                accessor: 'code'
            },
            {
                Header: 'Supplier Name',
                accessor: 'name'
            },
            {
                Header: 'Address',
                accessor: "address_str"
            }
        ],
        []
    )

    const getSuppliers = () => {
        SupplierService.getSupplier()
        .then((res) => {
            let suppliers = res.data;

            res.data.forEach((cur, i) => {
                if(cur.address !== undefined){
                    suppliers[i].address_str = Object.values(cur.address).toString();
                } else {
                    suppliers[i].address_str = "None"
                }
            });

            setSuppliers(suppliers)
        }).catch((err) => {
            if (err.response === undefined) {
                setAlert({visible: true, alertMsg: "Failed to connect to backend service", alertVariant: 'danger'})
            } else if (err.response.status === 404) {
                setAlert({visible: true, alertMsg: "No suppliers found in database", alertVariant: 'info'})
            } else {
                setAlert({visible: true, alertMsg: err.toString(), alertVariant: 'danger'})
            }
        console.log(err);
        });
    };

    const dismissErrorHandler = () => {
        setAlert({visible: false, alertMsg: "", alertVariant: 'danger'});
    }

    React.useMemo(() => getSuppliers(), [])

    return (
        <Container fluid style={{ marginTop: 50 }}>
            <Table
                columns={columns}
                data={suppliers}
            />
            <Alert variant={alert.alertVariant} dismissible='true' show={alert.visible} onClose={dismissErrorHandler} style={{position: 'fixed', bottom: 70, left: 270, right: 20}}>{alert.alertMsg}</Alert>
        </Container>
    )
}

export default SupplierTable
