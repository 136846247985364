import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTrashAlt, faEdit, faCartArrowDown, faSort} from "@fortawesome/free-solid-svg-icons";
import "./SupplierActionBar.css";

function SupplierActionBar(props) {
    let actionBarClass = "action-bar"
    if(props.hidden === true){
        actionBarClass = 'action-bar hidden'
    } else {
        actionBarClass = 'action-bar'
    }

    return (
        <div className={actionBarClass}>
            <a href={"/suppliers/create-supplier"} className="">
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                Add Supplier
            </a>
            <a className="">
                <FontAwesomeIcon icon={faEdit} className="mr-2" />
                Edit Supplier
            </a>
            <a className="danger">
                <FontAwesomeIcon icon={faTrashAlt} className="mr-2" />
                Delete Supplier
            </a>
        </div>
    );
}

export default SupplierActionBar;