import axios from 'axios'
import authHeader from "../helpers/AuthHeader";

const USER_REST_API_URL = 'https://inv-mngr-api.danieldalton.tech/users';

class UserService {

    getUserInfo(username) {
        return axios.get(USER_REST_API_URL + '/' + username, {headers: authHeader()});
    }
}

export default new UserService()
