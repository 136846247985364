import React, {Component} from 'react'
import {Alert, Button, Form} from "react-bootstrap";

import ProductService from "../../services/ProductService";

class ProductCreate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isInvalid: false,
            invalidMsg: "",

            partNumber: this.props.match.params.id,
            mfgPartNumber: this.props.match.params.mfgPartNumber,
            description: this.props.match.params.desc,
            minStock: this.props.match.params.minStock,
            costPrice: this.props.match.params.costPrice,
            currentStock: this.props.match.params.currentStock,
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.saveProduct = this.saveProduct.bind(this);
    }

    componentDidMount(){

    }

    saveProduct = (e) => {
        e.preventDefault();

        let product = {partNumber: this.state.partNumber, mfgPartNumber: this.state.mfgPartNumber, description: this.state.description,
            minStock: parseInt(this.state.minStock), costPrice: parseFloat(this.state.costPrice), currentStock: parseInt(this.state.currentStock)};
        console.log('product => ' + JSON.stringify(product));

        let productValid = this.validateProduct(product)

        if(productValid === "valid"){
            ProductService.createProduct(product).then(res =>{
                if(res.status === 201){
                    this.props.history.push('/products');
                    console.log("Successfully created product");
                } else if(res.status === 400) {
                    console.log("Failure: bad request 400");
                    this.props.history.push('/create-product');
                } else {
                    console.log("Unknown Error");
                }
            });
        } else {
            this.setState({
                isInvalid: true,
                invalidMsg: productValid + " is invalid",
            });
        }
    }
    
    validateProduct(product){
        Object.keys(product).forEach(key => {
            if (key === null || key === undefined) {
                return key
            }
        });
        return "valid"

        /*for (const key in product) {
            if(product[key] === null || product[key] === undefined) {
                return key
            }
        }
        return "valid"*/
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const type = target.type;
        const placeholder = target.placeholder;

        switch(type) {
            case "number":
                if(value < 0){
                    this.setState({
                        isInvalid: true,
                        invalidMsg: placeholder + " value must be positive",
                    });
                } else {
                    this.setState({isInvalid: false})
                }
                break;
            case "text":
                if(value === "" || value === " " || value === null || value === undefined){
                    this.setState({
                        isInvalid: true,
                        invalidMsg: placeholder + " field must not be empty",
                    });
                } else if(value.length <= 3){
                    this.setState({
                        isInvalid: true,
                        invalidMsg: placeholder + " must be longer than 3 characters",
                    });
                } else {
                    this.setState({isInvalid: false})
                }
                break;
            default:
                this.setState({isInvalid: false})
        }

        this.setState({
            [name]: value
        });
    }

    cancel(){
        this.props.history.push('/products');
    }

    render() {
        return (
            <div>
                <br/>
                <div className = "container">
                    <div className = "row">
                        <div className = "card col-md-6 offset-md-3 offset-md-3">
                            <h3 className="text-center" style={{ marginTop: 50 }}>Create Product</h3>
                            <div className = "card-body">
                                <Form>
                                    <Form.Group>
                                        <Form.Label> Part Number: </Form.Label>
                                        <Form.Control type="text" placeholder="Part Number" name="partNumber"
                                               value={this.state.partNumber} onChange={this.handleInputChange}/>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label> Manufacturers Part Number: </Form.Label>
                                        <Form.Control type="text" placeholder="Manufacturers Part Number:" name="mfgPartNumber"
                                               value={this.state.mfgPartNumber} onChange={this.handleInputChange}/>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label> Description: </Form.Label>
                                        <Form.Control type="text" placeholder="Description" name="description"
                                               value={this.state.description} onChange={this.handleInputChange}/>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label> Minimum Stock Level: </Form.Label>
                                        <Form.Control type="number" placeholder="Min Stock" name="minStock" defaultValue="0"
                                                      value={this.state.minStock} onChange={this.handleInputChange}/>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label> Cost Price (Eur): </Form.Label>
                                        <Form.Control type="number" placeholder="Cost Price" name="costPrice" defaultValue="0"
                                                      value={this.state.costPrice} onChange={this.handleInputChange}/>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label> Opening Stock: </Form.Label>
                                        <Form.Control type="number" placeholder="Current Stock" name="currentStock" defaultValue="0"
                                                      value={this.state.currentStock} onChange={this.handleInputChange}/>
                                    </Form.Group>

                                    <Alert variant="danger" show={this.state.isInvalid}>
                                        {this.state.invalidMsg}
                                    </Alert>

                                    <Button variant="success" onClick={this.saveProduct} disabled={this.state.isInvalid}>Save</Button>
                                    <Button variant="danger" onClick={this.cancel.bind(this)} style={{marginLeft: "10px"}}>Cancel</Button>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProductCreate