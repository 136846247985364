import React, {useState} from 'react';
import {Card, Col, Container, Form, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
import "./Login.css";
import logo from "../../images/icon-96x96.png";
import AuthService from "../../services/AuthService";
import PropTypes from 'prop-types';
import LoginBackground from "./LoginBackground";

async function loginUser(credentials) {
    return AuthService.login(credentials.username, credentials.password);
}

function Login({setToken}) {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [rememberMe, setRememberMe] = useState(false);
    const tooltipPlacement = 'right';

    const handleSubmit = async e => {
        e.preventDefault();
        const token = await loginUser({
            username,
            password
        });
        setToken(token, rememberMe);
    }


    return (
        <Container fluid>
            <LoginBackground/>
            <Row>
                <Col sm={9} md={7} lg={4} xl={3} className="col-sm-9 col-md-7 col-lg-4 mx-auto">
                    <Card className="card-signin my-5">
                        <Card.Header>
                            <img src={logo} alt="Box Icon"/>
                            <h2>Inventory Manager</h2>
                        </Card.Header>

                        <Card.Body>
                            <Card.Title className="text-center">Login</Card.Title>
                            <Form className="form-signin" onSubmit={handleSubmit}>
                                <Form.Group className="form-label-group">
                                    <Form.Control className="form-control" type="text" id="inputUsername"
                                                  name="loginUsername" placeholder="Username"
                                                  onChange={e => setUserName(e.target.value)}/>
                                    <label htmlFor="inputUsername">Username</label>
                                </Form.Group>

                                <Form.Group className="form-label-group">
                                    <Form.Control className="form-control" type="password" id="inputPassword"
                                                  name="loginPassword" placeholder="Password"
                                                  onChange={e => setPassword(e.target.value)}/>
                                    <label htmlFor="inputPassword">Password</label>
                                </Form.Group>
                                <div className="custom-control custom-checkbox mb-3">
                                    <input type="checkbox" className="custom-control-input" id="rememberMe"
                                           onChange={e => setRememberMe(e.target.checked)}/>
                                    <OverlayTrigger key={tooltipPlacement} placement={tooltipPlacement}
                                                    overlay={
                                                        <Tooltip id={`tooltip-${tooltipPlacement}`}>
                                                            Stay logged-in for <strong>5 days</strong>.
                                                        </Tooltip>
                                                    }>
                                        <label className="custom-control-label" htmlFor="rememberMe">
                                            Remember me &nbsp;
                                        </label>
                                    </OverlayTrigger>
                                </div>
                                <button className="btn btn-lg btn-primary btn-block text-uppercase" type='submit'>
                                    Sign in
                                </button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
};

export default Login;