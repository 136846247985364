import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import TransactionTable from "../components/transactions/TransactionTable";

const TransactionHome = () => {
    //const [email, setEmail] = useState('');
    //const [password, setPassword] = useState('');

    //TODO Make this contain all of the transaction state. All child components should derive there state from here

    return (
        <>
            <TransactionTable />
        </>
    )
};

export default TransactionHome;