import axios from 'axios'
import authHeader from "../helpers/AuthHeader";

const SUPPLIERS_REST_API_URL = 'https://inv-mngr-api.danieldalton.tech' + '/suppliers';

class SupplierService {

    getSupplier(){
        return axios.get(SUPPLIERS_REST_API_URL, {
            headers: authHeader(),
            responseType: 'json', //Default anyway
        });
    }

    createSupplier(supplier){
        return axios.post(SUPPLIERS_REST_API_URL, supplier, {headers: authHeader()});
    }

    getSupplierByCode(supplierCode){
        return axios.get(SUPPLIERS_REST_API_URL + '/' + supplierCode, {headers: authHeader()});
    }

    updateSupplier(supplier, supplierCode){
        return axios.put(SUPPLIERS_REST_API_URL + '/' + supplierCode, supplier, {headers: authHeader()});
    }

    deleteSupplier(supplierCode) {
        return axios.delete(SUPPLIERS_REST_API_URL + '/' + supplierCode, {headers: authHeader()})
    }
}

export default new SupplierService()
