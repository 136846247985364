import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {Card} from "react-bootstrap";

class DashboardCell extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    render() {
        return (
            <Card
                style={{ marginTop: 20 }}
                bg="dark"
                text='white'
                className="mb-2">
                <Card.Title style={{ textAlign: "center", marginTop: 5 }}>{this.props.title}</Card.Title>
                <div className="media d-flex">
                    <div className="align-self-center">
                        <i className="icon-pencil primary font-large-2 float-left"/>
                    </div>
                    <div className="media-body text-center">
                        <h3>{this.props.body}</h3>
                    </div>
                </div>
            </Card>
        );
    }
}

export default DashboardCell;