import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {Row, Container, Col} from "react-bootstrap";

import Cell from "../components/dashboard/DashboardCell";
import DashboardService from "../services/DashboardService";


class Dashboard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            totalProducts: 0,
            lowStockProducts: 0,
            stockTransCurrentWeek: 0,
            stockValue: 0,
            ordersPlaced: 0,
            invoicesDue: 0
        }
    }

    componentDidMount(){
        DashboardService.getStockValuation().then((res) => {
            this.setState({ stockValue: res.data['value']});
        });

        DashboardService.getProductCount().then((res) => {
            this.setState({totalProducts: res.data['productCount']})
        });

        DashboardService.getProductLowStockCount().then((res) => {
            this.setState({lowStockProducts: res.data['lowStockCount']})
        });
    }

    render() {
        return (
            <div>
                <Container fluid style={{ marginTop: 50 }}>
                    <Row>
                        <Col>
                            <Cell
                                title="Total Unique Products"
                                body={this.state.totalProducts}
                            />
                        </Col>
                        <Col>
                            <Cell
                                title="Low Stock Products"
                                body={this.state.lowStockProducts}
                            />
                        </Col>
                        <Col>
                            <Cell
                                title="Stock Out This Week"
                                body={this.state.stockTransCurrentWeek}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Cell
                                title="Stock Valuation"
                                body={"€" + this.state.stockValue.toFixed(2)}
                            />
                        </Col>
                        <Col>
                            <Cell
                                title="Orders Placed"
                                body={this.state.ordersPlaced}
                            />
                        </Col>
                        <Col>
                            <Cell
                                title="Invoices Due"
                                body={this.state.invoicesDue}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Dashboard;