import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import SupplierTable from "../components/suppliers/SupplierTable";
import SupplierBottomSpan from "../components/suppliers/SupplierActionBar";

const SupplierHome = () => {
    //const [email, setEmail] = useState('');
    //const [password, setPassword] = useState('');
    //const [isShown, toggleModal] = useModal();

    return (
        <>
            <SupplierTable />
            <SupplierBottomSpan/>
            {/*{this.state.editModal.modalShow &&
        <SupplierEditModal rowId={this.state.editModal.rowId} supplier={this.state.editModal.supplier}
                           showHide={this.state.editModal.modalShow}
                           onClick={this.handleEditModalSubmit} onHide={this.handleModalClose}/>
        }*/}
        </>
    )
};

export default SupplierHome;