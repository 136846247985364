import {useState} from 'react';

export default function useToken() {
    const getToken = () => {
        const tokenString = localStorage.getItem('user');
        const userToken = JSON.parse(tokenString);
        const currentTime = Date.now();

        if (userToken) {
            if (currentTime > Date.parse(userToken['expiryTime'])) {
                localStorage.removeItem('user');
                return '';
            }
        }

        return userToken?.token
    };

    const [token, setToken] = useState(getToken());

    const saveToken = (userToken, rememberMe) => {
        const createdTime = new Date(Date.now());
        const expiryTime = new Date(Date.now());

        if (rememberMe) {
            // Stay logged in for a week
            expiryTime.setHours(createdTime.getHours() + (24 * 5));
        } else {
            // Stay logged in for 2 hrs
            expiryTime.setHours(createdTime.getHours() + 2);
        }


        userToken['createdTime'] = createdTime;
        userToken['expiryTime'] = expiryTime;
        localStorage.setItem('user', JSON.stringify(userToken));
        setToken(userToken.token);
    };

    return {
        setToken: saveToken,
        token
    }
}