import React from 'react';
import logo from "../../images/icon-96x96.png";

function SidebarHeader(props) {
    return (
        <div className="sidebar-header" style={{ position: 'sticky', top: '0px'}}>
            <h3><img src={logo} alt="Box Icon" style={{ marginLeft: 5, marginBottom: 10}}/>Inventory Manager</h3>
        </div>
    );
}

export default SidebarHeader;