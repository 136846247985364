import axios from 'axios'
import authHeader from "../helpers/AuthHeader";

const DASHBOARD_REST_API_URL = 'https://inv-mngr-api.danieldalton.tech' + '/dashboard';

class DashboardService {

    getStockValuation(){
        return axios.get(DASHBOARD_REST_API_URL + '/valuation', {headers: authHeader()});
    }

    getProductCount(){
        return axios.get(DASHBOARD_REST_API_URL + '/product-count', {headers: authHeader()});
    }

    getProductLowStockCount(){
        return axios.get(DASHBOARD_REST_API_URL + '/product-low-stock', {headers: authHeader()});
    }
}

export default new DashboardService()
