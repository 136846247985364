import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import ProductHome from "./views/Products";
import SupplierHome from "./views/Suppliers";
import Login from "./components/login/Login";
import DashboardHome from "./views/Dashboard";
import ProductCreate from "./components/products/ProductCreate";
import SideBar from "./components/sidebar/Sidebar";

import "./App.css";
import SupplierCreate from "./components/suppliers/SupplierCreate";
import { Redirect } from "react-router-dom";
import TransactionHome from "./views/Transactions";
import useToken from './hooks/useToken';

function App(props) {
    const {token, setToken} = useToken();

    if (!token) {
        return <Login setToken={setToken}/>
    }

    return (
        <div className="App wrapper">
            <SideBar/>
            <Router>
                <div className="content is-open container-fluid">
                    <Switch>
                        <Route path="/" exact component={DashboardHome}/>

                        <Route path="/products" exact component={ProductHome}/>
                        <Route path="/products/create-product" exact component={ProductCreate}/>

                        <Route path="/suppliers" exact component={SupplierHome}/>
                        <Route path="/suppliers/create-supplier" exact component={SupplierCreate}/>

                        <Route path="/transactions" exact component={TransactionHome}/>

                        {/*Redirect all other urls to / (when logged in)*/}
                        <Route>
                            <Redirect to="/"/>
                        </Route>
                    </Switch>
                </div>
            </Router>
        </div>
    );
}

export default App;
