import React, {Component} from 'react'
import {Alert, Button, Form} from "react-bootstrap";

import SupplierService from "../../services/SupplierService";

class ProductCreate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            code: this.props.match.params.code,
            name: this.props.match.params.name,
            addressLineOne: this.props.match.params.addressLineOne,
            addressLineTwo: this.props.match.params.addressLineTwo,
            city: this.props.match.params.city,
            county: this.props.match.params.county,
            country: this.props.match.params.country,
            postCode: this.props.match.params.postCode,
            contactName: this.props.match.params.contactName,
            contactPhone: this.props.match.params.contactPhone,

            hasAlert: false,
            alertMsg: "",
            alertVariant: 'danger',
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.saveSupplier = this.saveSupplier.bind(this);
    }

    componentDidMount(){

    }

    saveSupplier = (e) => {
        e.preventDefault();

        let supplierAddress = {
            addressLineOne: this.state.addressLineOne,
            addressLineTwo: this.state.addressLineTwo,
            city: this.state.city,
            county: this.state.county,
            country: this.state.country,
            postCode: this.state.postCode
        }

        let supplierContact = {
            name: this.state.contactName,
            phone: this.state.contactPhone
        }

        let supplier = {
            code: this.state.code,
            name: this.state.name,
            address: supplierAddress,
            contact: [supplierContact]
        };

        console.log('supplier => ' + JSON.stringify(supplier));

        SupplierService.createSupplier(supplier).then(res =>{
            if(res.status === 201){
                //this.props.history.push('/suppliers');
                this.setState({hasAlert: true, alertMsg: "Successfully created supplier", alertVariant: 'success'});
                console.log("Successfully created supplier");
            } else if(res.status === 400) {
                this.setState({hasAlert: true, alertMsg: "Error: Supplier with that code already exists!", alertVariant: 'danger'})
                //this.props.history.push('/suppliers/create-supplier');
                console.log("Failure: bad request 400");
            } else {
                this.setState({hasAlert: true, alertMsg: res.statusText, alertVariant: 'danger'})
                console.log("Unknown Error");
            }
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value    });
    }

    cancel(){
        this.props.history.push('/suppliers');
    }

    dismissErrorHandler = (event) => {
        this.setState({hasAlert: false, alertMsg: ""});
    }

    render() {
        return (
            <div>
                <br/>
                <div className = "container">
                    <div className = "row">
                        <div className = "card col-md-6 offset-md-3 offset-md-3" style={{ marginBottom: 50}}>
                            <h3 className="text-center" style={{ marginTop: 50 }}>Create Product</h3>
                            <div className = "card-body">
                                <Form>
                                    <Form.Group>
                                        <label> Supplier Code </label>
                                        <Form.Control type="text" placeholder="Supplier Code" name="code"
                                                      value={this.state.code} onChange={this.handleInputChange}/>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label> Supplier Name </Form.Label>
                                        <Form.Control type="text" placeholder="Name" name="name"
                                                      value={this.state.name} onChange={this.handleInputChange}/>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label> Address Line 1 </Form.Label>
                                        <Form.Control type="text" placeholder="Address Line 1" name="addressLineOne"
                                                      value={this.state.addressLineOne} onChange={this.handleInputChange}/>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label> Address Line 2 </Form.Label>
                                        <Form.Control type="text" placeholder="Address Line 2" name="addressLineTwo"
                                                      value={this.state.addressLineTwo} onChange={this.handleInputChange}/>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label> City/Town: </Form.Label>
                                        <Form.Control type="text" placeholder="City" name="city"
                                                      value={this.state.city} onChange={this.handleInputChange}/>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label> County: </Form.Label>
                                        <Form.Control type="text" placeholder="County" name="county"
                                                      value={this.state.county} onChange={this.handleInputChange}/>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label> Country: </Form.Label>
                                        <Form.Control type="text" placeholder="Country" name="country" defaultValue="Ireland"
                                                      value={this.state.country} onChange={this.handleInputChange}/>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label> Postcode: </Form.Label>
                                        <Form.Control type="text" placeholder="Postcode" name="postCode"
                                                      value={this.state.postCode} onChange={this.handleInputChange}/>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label> Contact Name: </Form.Label>
                                        <Form.Control type="text" placeholder="Contact Name" name="contactName"
                                                      value={this.state.contactName} onChange={this.handleInputChange}/>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label> Contact Phone #: </Form.Label>
                                        <Form.Control type="phone" placeholder="Contact Phone #" name="contactPhone"
                                                      value={this.state.contactPhone} onChange={this.handleInputChange}/>
                                    </Form.Group>

                                    <Button variant="success" onClick={this.saveSupplier}>Save</Button>
                                    <Button variant="danger" onClick={this.cancel.bind(this)} style={{marginLeft: "10px"}}>Cancel</Button>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
                <Alert variant={this.state.alertVariant} show={this.state.hasAlert} onClose={this.dismissErrorHandler} style={{position: 'fixed', bottom: 5, left: 270, right: 20}}>{this.state.alertMsg}</Alert>
            </div>
        )
    }
}

export default ProductCreate