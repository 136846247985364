import axios from 'axios'
import authHeader from "../helpers/AuthHeader";

const TRANSACTIONS_REST_API_URL = 'https://inv-mngr-api.danieldalton.tech' + '/transactions';

class TransactionService {

    getTransactions() {
        return axios.get(TRANSACTIONS_REST_API_URL, {
            headers: authHeader(),
            responseType: 'json' //Default anyway
        });
    }

    createTransaction(transaction) {
        return axios.post(TRANSACTIONS_REST_API_URL, transaction, {headers: authHeader()});
    }

    getTransactionById(transactionId) {
        return axios.get(TRANSACTIONS_REST_API_URL + '/' + transactionId, {headers: authHeader()});
    }

    deleteTransaction(transactionId) {
        return axios.delete(TRANSACTIONS_REST_API_URL + '/' + transactionId, {headers: authHeader()})
    }
}

export default new TransactionService()
