import React from "react";
import {Nav} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function isActive(linkName) {
    if (linkName === window.location.pathname){
        return "active";
    } else {
        return "";
    }
}

function SidebarItem(props){
    return (
    <Nav.Item className={isActive(props.href)}>
        <Nav.Link href={props.href} eventKey={props.href} onSelect={props.onSelect} disabled={props.disabled}>
            <FontAwesomeIcon icon={props.icon} className="mr-2" />
            {props.text}
        </Nav.Link>
    </Nav.Item>
    );
}

export default SidebarItem;