import React, {useEffect, useState} from 'react';
import UserService from "../../services/UserService";
import AuthService from "../../services/AuthService";

function SidebarUserInfo(props) {
    const [username, setUsername] = useState(null);
    const [user, setUser] = useState({});

    useEffect(() => {
        setUsername(AuthService.getUsername());

        if (username) {
            UserService.getUserInfo(username).then(res => {
                setUser(res.data);
            });
        }
    }, [username]);

    return (
        <div>
            <p className="ml-3" style={{marginBottom: "5px"}}>{user.firstName} {user.lastName}</p>
            <p className="ml-3">Role: {user.role}</p>
        </div>
    );
}

export default SidebarUserInfo;