import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ProductTable from "../components/products/ProductTable";
import ProductBottomSpan from "../components/products/ProductActionBar";

class ProductHome extends Component {
    constructor(props) {
        super(props)

        this.state = {}
    }

    render() {
        return (
            <div>
                <ProductTable/>
                <ProductBottomSpan/>
            </div>
        );
    }
}

export default ProductHome;