export default function authHeader() {
    const tokenString = localStorage.getItem('user');
    const userToken = JSON.parse(tokenString);
    const token = userToken?.token

    if (token) {
        return {'Authorization': "Bearer " + token};
    } else {
        return {};
    }
}
