import axios from 'axios'

const AUTH_REST_API_URL = 'https://inv-mngr-api.danieldalton.tech' + '/auth';

class AuthService {

    login(username, password) {
        return axios.post(AUTH_REST_API_URL + '/login', {
            username: username,
            password: password
        }).then(res => {
            return {
                username: res.data.username,
                token: res.data.token
            }
        }).catch(function (error) {
            console.log(error);
            return undefined
        });
    }

    logout() {
        localStorage.removeItem("user");
    }

    register(username, email, password) {
        return axios.post(AUTH_REST_API_URL + "/register", {
            username,
            email,
            password
        });
    }

    getUsername() {
        const user = JSON.parse(localStorage.getItem('user'))
        return user['username'];
    }

    getToken() {
        const user = JSON.parse(localStorage.getItem('user'))
        return user['token'];
    }

    getRole() {
        return axios.get(AUTH_REST_API_URL + '/role');
    }
}

export default new AuthService()
