import React, {Component} from 'react'
import {Alert, Button, Form, Modal} from "react-bootstrap";

class ProductEditModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isInvalid: false,
            invalidMsg: "",

            product: this.props.product,
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {

    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const type = target.type;
        const placeholder = target.placeholder;

        switch(type) {
            case "number":
                if(value < 0){
                    this.setState({
                        isInvalid: true,
                        invalidMsg: placeholder + " value must be positive",
                    });
                } else {
                    this.setState({isInvalid: false})
                }
                break;
            case "text":
                if(value === "" || value === " " || value === null || value === undefined){
                    this.setState({
                        isInvalid: true,
                        invalidMsg: placeholder + " field must not be empty",
                    });
                } else if(value.length <= 3){
                    this.setState({
                        isInvalid: true,
                        invalidMsg: placeholder + " must be longer than 3 characters",
                    });
                } else {
                    this.setState({isInvalid: false})
                }
                break;
            default:
                this.setState({isInvalid: false})
        }

        this.setState({
            product: {
                [name]: value
            }
        });
    }

    render() {
        return (
            <div>
                <Modal show={this.props.showHide} onHide={() => this.props.onHide({ msg: 'Cross Icon Clicked!' })}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Edit Product: {this.props.product.partNumber}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Form>
                            <Form.Group>
                                <label> Part Number: </label>
                                <Form.Control type="text" placeholder="Part Number" name="partNumber" disabled={true}
                                              value={this.props.product.partNumber}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label> Manufacturers Part Number: </Form.Label>
                                <Form.Control type="text" placeholder="Manufacturers Part Number:" name="mfgPartNumber"
                                              value={this.state.product.mfgPartNumber} onChange={this.handleInputChange}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label> Description: </Form.Label>
                                <Form.Control type="text" placeholder="Description" name="description"
                                              value={this.state.product.description} onChange={this.handleInputChange}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label> Minimum Stock Level: </Form.Label>
                                <Form.Control type="number" placeholder="Min Stock" name="minStock"
                                              value={this.state.product.minStock} onChange={this.handleInputChange}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label> Cost Price (Eur): </Form.Label>
                                <Form.Control type="number" placeholder="Cost Price" name="costPrice"
                                              value={this.state.product.costPrice} onChange={this.handleInputChange}/>
                            </Form.Group>
                        </Form>
                        <Alert variant="danger" show={this.state.isInvalid}>
                            {this.state.invalidMsg}
                        </Alert>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.props.onHide()} >Close</Button>
                        <Button variant="primary" onClick={() => this.props.onClick({ partNumber: this.props.product.partNumber, product: this.state.product})}
                                disabled={this.state.isInvalid}>Update</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    };
}

export default ProductEditModal;