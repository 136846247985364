import React, {Component} from 'react'
import {Alert, Button, Form, Modal} from "react-bootstrap";

class ProductCheckoutModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isInvalid: true,
            invalidMsg: "Value must be greater than 0!",
            quantity: 0,
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {

    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if(value > this.props.currentStock){
            this.setState({
                isInvalid: true,
                invalidMsg: "Value must be less than current stock!",
            });
        } else if (value < 1){
            this.setState({
                isInvalid: true,
                invalidMsg: "Value must be greater than 0!",
            });
        } else {
            this.setState({isInvalid: false})
        }

        this.setState({
            [name]: value
        });
    }

    render() {
        return (
            <div>
                <Modal show={this.props.showHide} onHide={() => this.props.onHide({ msg: 'Cross Icon Clicked!' })}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Checkout Stock of: {this.props.partNumber}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>Current Stock: {this.props.currentStock}</p>
                        <Form>
                            <Form.Group>
                                <label> Quantity: </label>
                                <Form.Control type="number" placeholder="Quantity" name="quantity" isInvalid={this.state.isInvalid}
                                              value={this.state.quantity} onChange={this.handleInputChange}/>
                            </Form.Group>
                        </Form>
                        <Alert variant="danger" show={this.state.isInvalid}>
                            {this.state.invalidMsg}
                        </Alert>

                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.props.onHide()} >Close</Button>
                        <Button variant="primary" onClick={() => this.props.onClick({ partNumber: this.props.partNumber, quantity: this.state.quantity, oldStock: this.props.currentStock })} disabled={this.state.isInvalid}>Checkout</Button>
                    </Modal.Footer>

                </Modal>
            </div>
        )
    };


}

export default ProductCheckoutModal;