import React, {useState} from 'react';
import "./Login.css";
import Particles from "react-tsparticles";

function LoginBackground() {
    return (
        <Particles
            id="tsparticles"
            options={{
                fpsLimit: 60,
                background: {
                    color: "#0b032d"
                },
                backgroundMode: {
                    enable: true
                },
                particles: {
                    color: {
                        value: ["#f67e7d", "#843b62", "#621940"]
                    },
                    links: {
                        color: "#ffb997",
                        distance: 200,
                        enable: true
                    },
                    move: {
                        enable: true,
                        speed: 2
                    },
                    size: {
                        value: 5,
                        random: {
                            enable: true,
                            minimumValue: 1
                        },
                        animation: {
                            enable: true,
                            speed: 2,
                            minimumValue: 5
                        }
                    },
                    opacity: {
                        value: 0.8,
                        random: {
                            enable: true,
                            minimumValue: 0.4
                        }
                    }
                },
                detectRetina: true,
            }}
        />
        );
}

export default LoginBackground;